@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kavoon&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:700');

.about{
    position: relative;
    margin-top: 1rem;
    animation: slidein-about 1s 1.2s forwards;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@keyframes slidein-about{
  0%{
      transform: translate(1000px);
  }

  100%{
      transform: translate(0);
      opacity: 1;
  }
}

.container p {
    text-shadow: 0 0 7px rgba(255,255,255,.3), 0 0 3px rgba(255,255,255,.3);
    color: black;
  }
  
  .container {
    color: #e5e5e5;
    font-size: 2.26rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .animation {
    height:50px;
    overflow:hidden;
    margin-left: 1rem;
  }
  
  .animation > div > div {
    padding: 0.25rem 0.25rem;
    height:2.81rem;
    margin-bottom: 2.81rem;
    display:inline-block;
  }
  
  .animation div:first-child {
    animation: text-animation 12s infinite 2s;
  }
  
  .first div {
    background-color:darkgreen;
  }
  .second div {
    background-color:darkcyan;
  }
  .third div {
    background-color:darkmagenta;
  }
  
  @keyframes text-animation {
    0% {margin-top: 0;}
    10% {margin-top: 0;}
    20% {margin-top: -5.62rem;}
    30% {margin-top: -5.62rem;}
    40% {margin-top: -11.24rem;}
    60% {margin-top: -11.24rem;}
    70% {margin-top: -5.62rem;}
    80% {margin-top: -5.62rem;}
    90% {margin-top: 0;}
    100% {margin-top: 0;}
  }
  
  .emoji{
      display: inline-block;
      animation: wave .5s infinite alternate;
  }
  
  @keyframes wave{
      0%{
          transform: rotate(-20deg);
      }
  
      25%{
          transform: rotate(-10deg);
      }
  
      50%{
          transform: rotate(10deg);
      }
  
      100%{
          transform: rotate(20deg);
      }
  }

  .about > .info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
  }

  .about > .info p{
    font-family: 'Kavoon', cursive;
    max-width: 500px;
    font-size: 22px;
    text-align: center;
  }

  .about > .info img{
    /* width: 150px;
    border-radius: 100%;
    padding-top: 1rem;
    background-color: #20a7d8; */
    --s: 25px;  
    --b: 6px; 
    --w: 250px; 
    --c: black;
    
    width: var(--w);
    cursor: pointer;
    outline: var(--b) solid var(--c);
    outline-offset: calc(var(--s)/-2);
    --_i: calc(100% - 2*var(--s));
    padding: calc(.5*var(--s));
  }

  @media (max-width:800px){
    .about-container{
        display: flex;
        flex-direction: column;
    }
    .about-container p{
        font-size: 20px;
        max-width: 100%;
    }

    .about-container img{
        width: 70%;
    }

    .animation > div > div{
      font-size: 20px;
  }

  .container > p{
    font-size: 20px;
}


.about > .info p{
  max-width: 500px;
  font-size: 15px;
  padding: 1rem;
  text-align: center;
}

.about > .info img{
  width: 150px;
  margin-top: 20px;
  align-self: center;
}

.about > .info{
  flex-direction: column;
  gap: .3rem;
}
.animation > div > div{
  margin-bottom: 1.2rem;
}
}

