.skills-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3rem;
    padding-bottom:3rem ;
    box-shadow: 0 2px 2px #00000026;
    gap: 1rem;
    background-color: gainsboro;
}

.skills-container h1{
    align-self: flex-start;
    padding-left: 20px;
}


.img1{
    width: 100px;
    height: 100px;
    animation: icon 1s infinite alternate .5s;  
}

.img2{
    width: 100px;
    height: 100px;
    animation: icon 1s infinite alternate 1s;
}

.img3{
    width: 100px;
    height: 100px;
    animation: icon 1s infinite alternate 1.5s;

}

.img4{
    width: 100px;
    height: 100px;
    animation: icon 1s infinite alternate 2s;

}

.img5{
    width: 200px;
    height: 200px;
    animation: icon 1s infinite alternate 2.5s;
}

@keyframes icon{
    0%{
        transform: scale(1);
        transform: rotate(-5deg);
    }

    100%{
        transform: scale(1.2);
        transform: rotate(5deg);
    }
}


.skills-icons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.reveal{
    position: relative;
    opacity: 0;
    transition: 3s all ease;

  }
  .reveal.active{
    animation: slidein-project 1s forwards;
  }

  @keyframes slidein-project{
    0%{
        transform: translate(-1000px)
    }

    100%{
        transform: translate(0);
        opacity: 1;
    }
}

.hide{
    animation: slideout 1s forwards;
}

@keyframes slideout{
    0%{
        transform: translate(0);
        opacity: 1;
    }

    100%{
        transform: translate(-1000px);
        opacity: 0;
    }
}

@media (max-width:800px){
    .skills-icons img{
        width: 50px;
    }  
}