@import url('https://fonts.googleapis.com/css2?family=Kavoon&display=swap');

.contact-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5rem;
}
.reveal{
    position: relative;
    opacity: 0;
    transition: 3s all ease;

  }
  
  .reveal.active{
    animation: slidein-project 1s forwards;
  }

  @keyframes slidein-project{
    0%{
        transform: translate(-1000px)
    }

    100%{
        transform: translate(0);
        opacity: 1;
    }
}

.hide{
    animation: slideout 1s forwards;
}

@keyframes slideout{
    0%{
        transform: translate(0);
        opacity: 1;
    }

    100%{
        transform: translate(-1000px);
        opacity: 0;
    }
}

.contact-container h1{
    align-self: flex-start;
}


.contact-container form{ 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 2rem;
}

.contact-container form > input{
    width: 20rem;
    outline: none;
    background-color:rgba(224, 222, 222, 0.336);
    height: 2rem;
    border: none;
    border-radius: 5rem;
    border: 1px solid black;
    padding: .3rem;
}

.contact-container button{
    border: none;
    width: 20rem;
    padding: .7rem;
    border-radius: 5rem;
    background-color:cadetblue;
    font-size: 20px;
}

.contact-container button:hover{
    background-color: greenyellow;
    cursor: pointer;
}


.contact-container form > textarea{
    width: 20rem;
    outline: none;
    background-color:rgba(224, 222, 222, 0.336);
    height: 4rem;
    border-radius: .3rem;
    border: 1px solid black;
    padding: .3rem;
}

@media (max-width:800px){
    .contact-container{
        align-items: center;
        padding-bottom: 1rem;
    }
    
    .contact-container h1{
        align-self: center;
    }

    .contact-container form {
    align-items: center;
    
}
.contact-container form{
    padding: 0;
}
}