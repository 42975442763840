@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Press+Start+2P&display=swap');

body{
  margin: 0;
  padding: 0;
}

.header-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0 2px 2px #00000026;
  width: 100%;
  background-color: #17202A;
}

.h1-container{
    padding: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
}

.header-h1{
    overflow: hidden;
    border-right: .15em solid black;
    white-space: nowrap; 
    font-family: 'Press Start 2P', cursive;
    font-size: 25px;
    margin: 0 auto; 
    color: white;
    animation: 
      typing 1.5s steps(100, end),
      blink-caret .75s step-end infinite

 }

  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
  }

  .header-links{
    display: flex; 
    justify-content: space-between;
    gap: 2rem;
  }

  .header-links h1{
    color: aliceblue;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .header-links h1:hover{
    color: antiquewhite;
  }

  @media (max-width:800px){
    .header-h1{
      font-size: 13px;
    }
    
    .header-links{
      display: none;
    }
  }