@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700;900&display=swap');

.project-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid black;
}

.project-card{
    display: grid;
    position: relative;
    grid-template-columns: auto auto auto;
    grid-auto-flow: column;
    align-items: center;
    justify-items: center;
    border: 1px solid black;
    width: 20rem;
    height: 12rem;
}

.project-card img{
    width: 100%;
    height: 100%;
}

.project-card a{
    width: 100%;
    height: 100%;
}

.project-title{
    color:black;
    font-size: 25px;
    position:absolute;
    top:0px;
    z-index: 9;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 25px;
    letter-spacing: 2px;
}

.projects{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.reveal{
    position: relative;
    opacity: 0;
    transition: 3s all ease;

  }
  .reveal.active{
    animation: slidein-project 1s forwards;
  }

  @keyframes slidein-project{
    0%{
        transform: translate(-1000px)
    }

    100%{
        transform: translate(0);
        opacity: 1;
    }
}

.hide{
    animation: slideout 1s forwards;
}

@keyframes slideout{
    0%{
        transform: translate(0);
        opacity: 1;
    }

    100%{
        transform: translate(-1000px);
        opacity: 0;
    }
}

@media (max-width:800px){
    .project-card{
        width: auto;
    }

    .projects h1{
        font-size: 20px;
    }

    .projects{
        align-items: center;
        margin: 2rem;
    }

    .project-container{
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }

    .project-title{
        font-size:14px ;
    }

    .project-card:hover{
        transform: none;
    }

}
