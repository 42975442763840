@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:700');


body{
    background-color: #F4F6F7;
}

*{
    font-family: 'Inter', sans-serif;
}

body{
    height: 100vh;
}

.main-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:6rem;
}

.reveal{
    position: relative;
    opacity: 0;
    transition: 3s all ease;

  }
  .reveal.active{
    animation: slidein-project 1s forwards;
  }

  @keyframes slidein-project{
    0%{
        transform: translate(-1000px)
    }

    100%{
        transform: translate(0);
        opacity: 1;
    }
}

.hide{
    animation: slideout 1s forwards;
}

@keyframes slideout{
    0%{
        transform: translate(0);
        opacity: 1;
    }

    100%{
        transform: translate(-1000px);
        opacity: 0;
    }
}

@media (max-width:800px){
    .main-content{
        gap: 2rem;
    }
}